html {
  scroll-behavior: smooth;
}

.card {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.card-skills {
  position: relative;
  margin-left: 25px;
  width: 110px;
  height: 110px;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.overlay-content {
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white; 
  transition: opacity 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
  font-size: 1em;
  position: absolute; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; 
  box-sizing: border-box; 
}

.card:hover .card-img {
  transform: scale(1.2);
}

.card:hover .overlay-content {
  opacity: 1;
}

@media (max-width: 1600px) {
  .overlay-content {
    font-size: 1em; 
  }
}

@media (max-width: 1200px) {
  .overlay-content {
    font-size: .8em; 
  }
}

@media (max-width: 992px) {
  .overlay-content {
    font-size: .8em; 
  }
}

@media (max-width: 768px) {
  .overlay-content {
    font-size: 1em; 
  }
}

.overlay-content .d-flex {
  gap: 1rem; 
}

.overlay-content i {
  font-size: 1.5em; 
}

@keyframes scrollInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes scrollInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.scroll-in-left {
  animation: scrollInLeft 1s ease-out;
}

.scroll-in-right {
  animation: scrollInRight 1s ease-out;
}

.icon-text-wrapper {
  display: inline-block;
  transition: transform 0.2s ease-in-out;
}

.icon-text-wrapper:hover {
  transform: scale(1.2);
}

.interactive-icon {
  display: block;
  margin: 0 auto;
}

.interactive-text {
  text-align: center;
  margin-top: 0.5rem;
}

.section {
  padding-top: 70px; 
}

/* Custom Colors */
.bg-darkBlue {
  background-color: #000f54;
}

.text-darkBlue {
  color: #000a38; 
}
.bg-lighterDarkBlue{
  background-color: #011987;
}

.bg-lightBlue {
  background-color: #A2BFFE;
}

.text-White {
  color: #ffffff; 
}

.text-Black {
  color: #000000; 
}

.wh-White {
  color: #ffffff; 
  transition: color 0.1s ease; 
}

.wh-White:hover {
  color: #ccccccb9; 
}

.border-White {
  border: 1px solid #ffffff; 
  transition: border-color 0.1s ease; 
}

.border-White:hover {
  border-color: #ccccccb9; 
}

.border-Black {
  border: 1px solid #000000; 
  transition: border-color 0.1s ease; 
}

.border-Black:hover {
  border-color: #2e2e2eb9; 
}

.wh-Black {
  color: #000000; 
  transition: color 0.1s ease; 
}

.wh-Black:hover {
  color: #2e2e2eb9; 
}

.wh-White:visited,
.wh-White:active {
  color: #ffffff;
  border-color: #ffffff;
}

html, body {
  height: 100%;
  margin: 0;
}

